$imgPath: '/assets/all-in-one-theme/images';
$fontPath: '/assets/all-in-one-theme/fonts';
$themeVariantImgPath: '/assets/all-in-one-theme/images/theme-variants/bringo-romania-theme';
$colors: ('primary': red, 'color_1': #4A397E, 'color_2': #FFB600, 'color_3': #2DA771, 'color_4': #6D6D6D, 'color_5': #F42626, 'color_6': #EAB818, 'color_7': #FAB72C, 'color_8': #F5F5F5, 'color_9': #E9B818, 'color_10': #362768, 'color_11': rgba(74, 57, 126, 0.85), 'color_12': rgba(74, 57, 126, 1), 'color_13': #FFB600, 'color_14': #FFB600, 'color_15': #362768, 'color_16': #273C51);
$header: ('logo_img': 'bringo-logo.svg', 'logo_img_mobile': 'bringo-logo.svg', 'logo_width': 146px, 'logo_height': 46px, 'logo_width_mobile': 120px, 'logo_height_mobile': 38px, 'nav_width': 125px, 'nav_height': 40px, 'nav_margin_top': 0, 'nav_bg_img': 'bringo-logo.svg', 'hamburger_position_top': 27px);
$units: ('hamburger_menu_icon_top': 10px, 'nav_center_column_mt': 0, 'logo_width': 154px);
$fonts: ('default': 'Manrope');@charset "UTF-8";
/* Slider */
.slick-loading .slick-list
{
    background: #fff url($imgPath + '/ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url($fontPath + '/slick.eot');
    src: url($fontPath + '/slick.eot?#iefix') format('embedded-opentype'), url($fontPath + '/slick.woff') format('woff'), url($fontPath + '/slick.ttf') format('truetype'), url($fontPath + '/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 15px;
    z-index: 2;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    /*content: '←';*/
    background-size: 100% 100%;
    background-position: center center;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url($themeVariantImgPath + '/icons/left-arrow-action-icon.png');
    background-repeat: no-repeat;
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: 32px;
    z-index: 2;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    /*content: '→';*/
    background-size: 100% 100%;
    background-position: center center;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url($themeVariantImgPath + '/icons/right-arrow-action-icon.png');
    background-repeat: no-repeat;
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    /*margin-bottom: 30px;*/
}

.slick-dots
{
    position: absolute;
    bottom: -13px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    z-index: 9;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 10px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: 1;
    color: #ffffff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: 1;
    color: #7922b2;
}

.slick-slide img {
    display: block;
    width: 100%;
    height: auto;
}

.third-row-slider .slick-slide {
    margin: 10px;
    -webkit-box-shadow: 0 5px 8px 0 rgba(0,0,0,0.36);
    -moz-box-shadow: 0 5px 8px 0 rgba(0,0,0,0.36);
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.36);
}

.third-row-slider .slick-list {
    margin: 0 -10px;
}

.third-row-slider .slick-prev {
    left: -6%;
}

.third-row-slider .slick-next {
    right: -5%;
}
